import React from "react";
import { getAuth } from "firebase/auth";

const EmbeddedCheckoutButton = () => {
  const handleCheckoutClick = async () => {
    try {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (!userId) {
        alert("Please log in to continue.");
        return;
      }

      // Send a request to your Firebase Function
      const response = await fetch(
        "https://createcheckoutsession-ibj6fdglpa-uc.a.run.app", // Replace with your deployed function URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId: "price_1QdDz7BI16uD9m5zsDpZg32L", // Replace with your actual Price ID
            userId, // Pass the user ID to the backend
          }),
        }
      );

      const data = await response.json();

      if (data.url) {
        // Redirect to Stripe-hosted checkout page
        window.location.href = data.url;
      } else {
        alert("Failed to create a checkout session. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching checkout session:", error);
      alert("Something went wrong. Please try again later.");
    }
  };

  return (
    <div>
      {/* Button to trigger payment */}
      <button
        onClick={handleCheckoutClick}
        className="mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
      >
        Start 7-day free trial
      </button>
    </div>
  );
};

export default EmbeddedCheckoutButton;
