import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { DataContext } from '../context/DataContext';
import SingleQuiz from '../components/SingleQuiz';

export default function LessonView() {
  const { course, loading, error } = useContext(DataContext);
  const location = useLocation();

  // Get BOTH lessonId and sectionId from the URL
  const searchParams = new URLSearchParams(location.search);
  const lessonId = searchParams.get('lessonId');
  const sectionId = searchParams.get('sectionId');

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!course) return <div>No data available</div>;

  // Find the corresponding lesson
  let foundLesson = null;
  course.sections.forEach((section) => {
    if (section.id === sectionId) {
      const matched = section.lessons?.find((lesson) => lesson.id === lessonId);
      if (matched) foundLesson = matched;
    }
  });

  if (!foundLesson) {
    return <div>Lesson not found.</div>;
  }

  // Render based on lesson type
  if (foundLesson.type === 'PRACTICE') {
    return (
      <div className="">
        {/* Pass in the lesson + both IDs if needed */}
        <SingleQuiz
          lesson={foundLesson}
          lessonId={lessonId}
          sectionId={sectionId}
        />
      </div>
    );
  } else if (foundLesson.type === 'LEARN') {
    return (
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">{foundLesson.title}</h1>
        <p>{foundLesson.content}</p>
      </div>
    );
  } else {
    // Fallback
    return (
      <div className="p-4">
        <h2 className="text-lg font-bold mb-2">{foundLesson.title}</h2>
        <p>{JSON.stringify(foundLesson, null, 2)}</p>
      </div>
    );
  }
}
