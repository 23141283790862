// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../utils/firebase'; // Ensure you have db exported from your firebase utils

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null); // Store the user object (including uid)
  const [loading, setLoading] = useState(true); // Loading state
  const [hasPaid, setHasPaid] = useState(false); // Add hasPaid state

  useEffect(() => {
    const auth = getAuth(); // Get Firebase Auth instance
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // User is logged in
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
        });

        // Fetch user data from Firestore to get hasPaid status
        try {
          const userDocRef = doc(db, 'users', firebaseUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            setHasPaid(data.hasPaid || false);
          } else {
            setHasPaid(false); // Default to false if user doc doesn't exist
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setHasPaid(false); // Default to false on error
        }
      } else {
        // User is logged out
        setUser(null);
        setHasPaid(false); // Reset hasPaid when logged out
      }
      setLoading(false); // Auth check is done
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const logout = async () => {
    const auth = getAuth();
    await signOut(auth);
    setUser(null); // Clear user state
    setHasPaid(false); // Reset hasPaid on logout
    console.log('signed out');
  };

  return (
    <AuthContext.Provider value={{ user, logout, loading, hasPaid }}>
      {children}
    </AuthContext.Provider>
  );
}
