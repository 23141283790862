// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from 'firebase/auth'

import { getFirestore } from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAR_bXsI0Z_1xJCg5w0yOjYz_enqaUHqjQ",
  authDomain: "avantdemyfinal-db4a4.firebaseapp.com",
  projectId: "avantdemyfinal-db4a4",
  storageBucket: "avantdemyfinal-db4a4.firebasestorage.app",
  messagingSenderId: "350400053003",
  appId: "1:350400053003:web:09bd601bd4b069b92cec0e",
  measurementId: "G-E0E0VSY50K"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app);


const functions = getFunctions(app);
export const generateTutorResponse = httpsCallable(functions, "generateTutorResponse");

