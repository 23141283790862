import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const CancelSubscription = () => {
  const { user } = useContext(AuthContext);
  const db = getFirestore();
  const navigate = useNavigate();

 const handleCancelSubscription = async () => {
  if (!user) {
    alert('You need to be logged in to cancel your subscription.');
    return;
  }

  console.log('User ID:', user.uid); // Log user ID for debugging

  try {
    const response = await fetch('https://cancelsubscription-ibj6fdglpa-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: user.uid }),
    });

    console.log('Response:', response); // Log response details

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Server Error:', errorData); // Log server error details
      throw new Error('Failed to cancel subscription on the server.');
    }

    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, { hasPaid: false });

    alert('Your subscription has been successfully canceled.');
    navigate('/dashboard/learn');
  } catch (error) {
    console.error('Error canceling subscription:', error);
    alert('Failed to cancel subscription. Please contact support.');
  }
};


  return (
    <div className="p-6 max-w-lg mx-auto">
      <h1 className="text-2xl font-bold mb-6">Cancel Subscription</h1>
      <p className="text-gray-600 mb-4">
        Are you sure you want to cancel your subscription? This action cannot be undone.
      </p>
      <a
        href="/dashboard/learn"
        onClick={(e) => {
          e.preventDefault();
          handleCancelSubscription();
        }}
        className="text-purple-600 hover:underline"
      >
        Cancel Subscription
      </a>
    </div>
  );
};

export default CancelSubscription;
