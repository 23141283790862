import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { format, parseISO, isBefore, isAfter, addHours } from "date-fns";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../utils/firebase"; // Ensure this path matches your project structure

const formatSessionDate = (dateString) => {
  const dateObj = parseISO(dateString);
  return format(dateObj, "d MMMM EEEE");
};

const formatSessionTime = (timeString) => {
  return format(parseISO(timeString), "h:mm a");
};

const getSessionStatus = (startTimeStr) => {
  const startTime = parseISO(startTimeStr);
  const now = new Date();
  const oneHourAfterStart = addHours(startTime, 1);

  if (isBefore(now, startTime)) {
    return "upcoming";
  } else if (isAfter(now, startTime) && isBefore(now, oneHourAfterStart)) {
    return "in-progress";
  } else {
    return "expired";
  }
};

export default function Live() {
  const { user, loading } = useContext(AuthContext);
  const [sessions, setSessions] = useState([]);
  const [pastRecordings, setPastRecordings] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        setIsLoading(true);
        // Fetch live sessions
        const liveSessionsRef = collection(db, 'sessions', 'live', 'sessions');
        const liveSessionsSnap = await getDocs(liveSessionsRef);
        const liveSessions = liveSessionsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Fetch past recordings
        const pastSessionsRef = collection(db, 'sessions', 'pastSessions', 'sessions');
        const pastSessionsQuery = query(pastSessionsRef, orderBy('startTime', 'desc'));
        const pastSessionsSnap = await getDocs(pastSessionsQuery);
        const pastSessions = pastSessionsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setSessions(liveSessions);
        setPastRecordings(pastSessions);
      } catch (error) {
        console.error("Error fetching sessions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSessions();
  }, []);

  if (loading || isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const sessionsByDate = sessions.reduce((acc, session) => {
    const dateKey = session.date;
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(session);
    return acc;
  }, {});

  const sortedDates = Object.keys(sessionsByDate).sort();

  const filteredDates = sortedDates.filter((dateKey) => {
    if (activeTab === "upcoming") {
      return sessionsByDate[dateKey].some(
        (s) => getSessionStatus(s.startTime) !== "expired"
      );
    }
    return false;
  });

  return (
    <div className="max-w-2xl mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Live Sessions</h1>
      
      <div className="flex border-b border-gray-300 mb-4">
        {/* Updated Tab Buttons with Smaller Text */}
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "upcoming"
              ? "border-b-2 border-teal-500 text-black"
              : "text-black hover:text-teal-500"
          }`}
          onClick={() => setActiveTab("upcoming")}
        >
          Upcoming
        </button>
        <button
          className={`px-4 py-2 text-sm font-medium ${
            activeTab === "past"
              ? "border-b-2 border-teal-500 text-black"
              : "text-black hover:text-teal-500"
          }`}
          onClick={() => setActiveTab("past")}
        >
          Past Recordings
        </button>
      </div>

      {activeTab === "upcoming" ? (
        filteredDates.length === 0 ? (
          <p className="text-gray-600">No upcoming sessions available.</p>
        ) : (
          filteredDates.map((dateKey) => (
            <div key={dateKey} className="mb-6">
              {/* Updated Date with Smaller Text */}
              <h2 className="text-lg font-semibold text-gray-700 mb-3">
                {formatSessionDate(dateKey)}
              </h2>
              <div className="space-y-4">
                {sessionsByDate[dateKey]
                  .sort((a, b) => parseISO(a.startTime) - parseISO(b.startTime))
                  .map((session) => {
                    const status = getSessionStatus(session.startTime);
                    return (
                      <div key={session.id} className="bg-white shadow rounded-lg p-4">
                        <div className="flex justify-between items-center gap-3">
                          <div>
                            <div className="text-sm text-gray-600">
                              {formatSessionTime(session.startTime)} · {session.location}
                            </div>
                            <h3 className="text-lg font-semibold mt-1">{session.title}</h3>
                            <p className="text-sm text-gray-600 mt-1">Host: {session.host}</p>
                          </div>
                          <div className="flex items-center gap-3">
  {status === "in-progress" ? (
    <a
      href={session.zoomLink}
      target="_blank"
      rel="noopener noreferrer"
      className="px-4 py-2 text-sm font-medium rounded-md bg-teal-500 hover:bg-teal-600 text-white"
    >
      Join 
    </a>
  ) : (
    <button
      className={`px-4 py-2 text-sm font-medium rounded-md ${
        status === "expired"
          ? "bg-gray-400 cursor-not-allowed"
          : "bg-teal-500 hover:bg-teal-600"
      } text-white`}
      disabled={status === "expired"}
    >
      {status === "expired" ? "Ended" : "Going"}
    </button>
  )}
</div>

                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))
        )
      ) : (
        <div className="space-y-4">
          {pastRecordings.length === 0 ? (
            <p className="text-gray-600">No past recordings available.</p>
          ) : (
            pastRecordings.map((recording) => (
              <div key={recording.id} className="bg-white shadow rounded-lg p-4">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-sm text-gray-600">
                      {formatSessionDate(recording.date)} · {formatSessionTime(recording.startTime)}
                    </div>
                    <h3 className="text-lg font-semibold mt-1">{recording.title}</h3>
                    <p className="text-sm text-gray-600 mt-1">Host: {recording.host}</p>
                  </div>
                  <a
                    href={recording.recordingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-4 py-2 text-sm font-medium rounded-md bg-teal-600 hover:bg-teal-700 text-white"
                  >
                    Watch Recording
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
