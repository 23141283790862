import React, { createContext, useState, useEffect, useContext } from "react";
import { getFirestore, doc, getDoc, collection, getDocs, query } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { AuthContext } from "./AuthContext";

// Firebase configuration (or import from `firebase.js`)
const firebaseConfig = {
  apiKey: "AIzaSyAR_bXsI0Z_1xJCg5w0yOjYz_enqaUHqjQ",
  authDomain: "avantdemyfinal-db4a4.firebaseapp.com",
  projectId: "avantdemyfinal-db4a4",
  storageBucket: "avantdemyfinal-db4a4.firebasestorage.app",
  messagingSenderId: "350400053003",
  appId: "1:350400053003:web:09bd601bd4b069b92cec0e",
  measurementId: "G-E0E0VSY50K"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Create the context
export const DataContext = createContext(null);

// Provider component
export function DataProvider({ children }) {
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // New states for user progress
  const [userProgress, setUserProgress] = useState({}); 
  const [loadingProgress, setLoadingProgress] = useState(false);

  // Get user from AuthContext (if you have one)
  const { user } = useContext(AuthContext); 

  // Fetch SAT data (as you do now)
  useEffect(() => {
    const fetchSATData = async () => {
      try {
        const courseRef = doc(db, "courses", "sat");
        const courseSnap = await getDoc(courseRef);

        if (!courseSnap.exists()) {
          setCourse(null);
          setLoading(false);
          return;
        }

        const courseData = { id: courseSnap.id, ...courseSnap.data() };
        const sectionsRef = collection(courseRef, "sections");
        const sectionsSnap = await getDocs(sectionsRef);

        const sectionsPromises = sectionsSnap.docs.map(async (sectionDoc) => {
          const sectionData = { id: sectionDoc.id, ...sectionDoc.data() };
          const lessonsRef = collection(sectionDoc.ref, "lessons");
          const lessonsSnap = await getDocs(lessonsRef);

          const lessonsData = lessonsSnap.docs.map((lessonDoc) => ({
            id: lessonDoc.id,
            ...lessonDoc.data(),
          }));

          return { ...sectionData, lessons: lessonsData };
        });

        const sectionsData = await Promise.all(sectionsPromises);

        const satCourseWithSections = {
          ...courseData,
          sections: sectionsData,
        };

        setCourse(satCourseWithSections);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchSATData();
  }, []);

  // Fetch user progress if user is logged in
  useEffect(() => {
    const fetchUserProgress = async () => {
      if (!user) {
        // If user is not logged in, reset progress to {}
        setUserProgress({});
        return;
      }

      setLoadingProgress(true);
      try {
        // Example doc path: /users/{userId}/progress
        // If you store each progress doc keyed by {sectionId}, you can read them all:
        const progressCollectionRef = collection(db, "users", user.uid, "progress");
        const snapshot = await getDocs(progressCollectionRef);

        // We'll store progress in a simple object: { sectionId1: [lessonIds], sectionId2: [lessonIds], ... }
        const progressData = {};

        snapshot.forEach((docSnap) => {
          // docSnap.id is the sectionId
          // docSnap.data().completedLessons is an array of lessonIds
          progressData[docSnap.id] = docSnap.data().completedLessons || [];
        });

        setUserProgress(progressData);
      } catch (err) {
        console.error("Error fetching user progress:", err);
      } finally {
        setLoadingProgress(false);
      }
    };

    fetchUserProgress();
  }, [user]);

  return (
    <DataContext.Provider
      value={{
        course,
        loading,
        error,
        userProgress,      // Provide userProgress
        setUserProgress,   // Provide the setter
        loadingProgress,   // If you want to show a spinner while loading user progress
      }}
    >
      {children}
    </DataContext.Provider>
  );
}