// src/components/DashboardLayout.js

import React, { useContext, useEffect, useState } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { AuthContext } from '../context/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

/**
 * Utility function to compute initials from a full name.
 * @param {string} name - The full name of the user.
 * @returns {string} The initials derived from the name.
 */
function getInitials(name) {
  if (!name) return '';
  const nameParts = name.trim().split(' ');
  const initials = nameParts.map(part => part[0].toUpperCase());
  return initials.slice(0, 2).join('');
}

// Navigation items for the dashboard
const navigationItems = [
  { name: 'Learn', to: '/dashboard/learn' },
  { name: 'Live Sessions', to: '/dashboard/live' },
];

// Helper function to concatenate class names conditionally
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DashboardLayout = () => {
  const { user, logout } = useContext(AuthContext); // Access user and logout function
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [hasPaid, setHasPaid] = useState(false);
  const [userName, setUserName] = useState('');

  const db = getFirestore();

  useEffect(() => {
    // Fetch user data from Firestore
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setHasPaid(data.hasPaid || false);
            setUserName(data.name || user.displayName || 'User');
          } else {
            // If user document doesn't exist, fallback to Firebase Auth displayName
            setUserName(user.displayName || 'User');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserName(user.displayName || 'User');
        }
      }
    };
    fetchUserData();
  }, [user, db]);

  const handleCancelSubscription = () => {
    navigate('/cancel'); // Navigate to the cancel subscription page
  };

  const handleLinkClick = (to) => {
    setActiveLink(to);
  };

  // Updated userNavigation with 'to' for Settings
  const userNavigation = [
    { name: 'Your Profile', to: '/profile' },
    { name: 'Settings', to: '/settings' }, // Changed from href to to

 
  ].filter(Boolean); // Remove any undefined items

  return (
    <div className="min-h-screen">
      {/* Fixed Header */}
      <Disclosure
        as="nav"
        className="bg-white shadow border-b border-gray-200 fixed top-0 left-0 right-0 z-50"
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                {/* Logo and Navigation Links */}
                <div className="flex items-center">
                  <div className="shrink-0">
                    <Link to="/" className="text-2xl font-medium font-inter">
                      Avantdemy
                    </Link>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigationItems.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          onClick={() => handleLinkClick(item.to)}
                          className={classNames(
                            activeLink === item.to
                              ? 'bg-indigo-100 text-indigo-700'
                              : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Upgrade Button and User Menu */}
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <Link
                      to="/store"
                      className="relative rounded-full bg-teal-500 text-white px-3 py-1 text-sm font-medium hover:bg-tea-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white"
                    >
                      {hasPaid ? 'Plus' : 'Upgrade'}
                    </Link>

                    <Menu as="div" className="relative ml-3">
                      <div>
                        <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white">
  <span className="absolute -inset-1.5" />
  <span className="sr-only">Open user menu</span>
  {/* Conditionally display user photo or initials with Tailwind purple */}
  {user.photoURL ? (
    <img
      src={user.photoURL}
      alt="User avatar"
      className="h-8 w-8 rounded-full"
    />
  ) : (
    <div className="h-8 w-8 rounded-full bg-purple-400 flex items-center justify-center text-white font-medium">
      {getInitials(userName)}
    </div>
  )}
</MenuButton>

                      </div>
                      <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5">
                        {userNavigation.map((item) =>
                          item.to ? (
                            // Use Link for navigation items with 'to'
                            <MenuItem key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.to}
                                  onClick={() => {
                                    if (item.to) handleLinkClick(item.to);
                                  }}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </MenuItem>
                          ) : (
                            // Use button for action items
                            <MenuItem key={item.name}>
                              {({ active }) => (
                                <button
                                  onClick={item.action}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block w-full text-left px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </button>
                              )}
                            </MenuItem>
                          )
                        )}
                      </MenuItems>
                    </Menu>
                  </div>
                </div>

                {/* Mobile Menu Button */}
                <div className="-mr-2 flex md:hidden">
                  <Disclosure.Button className="group relative inline-flex items-center justify-center rounded-md bg-gray-100 p-2 text-gray-500 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon
                      aria-hidden="true"
                      className="block h-6 w-6 group-data-[open]:hidden"
                    />
                    <XMarkIcon
                      aria-hidden="true"
                      className="hidden h-6 w-6 group-data-[open]:block"
                    />
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            {/* Mobile Menu Panel */}
            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigationItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    onClick={() => handleLinkClick(item.to)}
                    className={classNames(
                      activeLink === item.to
                        ? 'bg-indigo-100 text-indigo-700'
                        : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="flex items-center px-5">
                  {/* Conditionally display user photo or initials */}
                  {user.photoURL ? (
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.photoURL}
                      alt="User avatar"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-indigo-500 flex items-center justify-center text-white font-medium">
                      {getInitials(userName)}
                    </div>
                  )}
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {userName || 'User'}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {user?.email || 'user@example.com'}
                    </div>
                  </div>
                  <button
                    onClick={logout}
                    className="ml-auto flex-shrink-0 rounded-full bg-gray-100 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  >
                    <span className="sr-only">Sign out</span>
                    {/* Replace with an icon if desired */}
                    Logout
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) =>
                    item.to ? (
                      <Link
                        key={item.name}
                        to={item.to}
                        onClick={() => handleLinkClick(item.to)}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    ) : (
                      <button
                        key={item.name}
                        onClick={item.action}
                        className="block w-full text-left rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        {item.name}
                      </button>
                    )
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Main Content */}
      <main className="pt-16"> {/* Adjust padding-top to match header height */}
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default DashboardLayout;