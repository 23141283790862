import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const { user, logout } = useContext(AuthContext); // Use 'logout' consistently
  const db = getFirestore();
  const navigate = useNavigate();
  const [hasPaid, setHasPaid] = useState(false); // Subscription status
  const [loading, setLoading] = useState(true); // Loading state for fetching 'hasPaid'
  const [isSigningOut, setIsSigningOut] = useState(false); // Loading state for sign out

  useEffect(() => {
    // Fetch the user's payment status from Firestore
    const fetchPaymentStatus = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setHasPaid(userDoc.data().hasPaid || false);
          } else {
            console.warn('No such user document!');
            setHasPaid(false);
          }
        } catch (error) {
          console.error('Error fetching payment status:', error);
          alert('Failed to fetch subscription status. Please try again.');
          setHasPaid(false);
        } finally {
          setLoading(false);
        }
      } else {
        setHasPaid(false);
        setLoading(false);
      }
    };

    fetchPaymentStatus();
  }, [user, db]);

  const handleCancelSubscription = () => {
    navigate('/cancel'); // Navigate directly to the cancellation page
  };

  const handleSignOut = async () => {
    setIsSigningOut(true); // Start loading
    try {
      await logout(); // Await the logout process
      alert('Successfully signed out.');
      navigate('/'); // Navigate after successful logout
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to sign out. Please try again.');
    } finally {
      setIsSigningOut(false); // End loading
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-lg text-gray-600">Loading your settings...</p>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-lg mx-auto">
      <h1 className="text-2xl font-bold mb-6">Settings</h1>

      {/* Subscription Section - Visible Only If User Has Paid */}
      {hasPaid && (
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700">Subscription</h2>
          <p className="mt-1 text-gray-600">Manage your subscription preferences below.</p>
          <a
            href="/cancel"
            onClick={(e) => {
              e.preventDefault();
              handleCancelSubscription();
            }}
            className="mt-4 text-purple-600 hover:underline"
          >
            Cancel Subscription
          </a>
        </div>
      )}

      {/* Account Section */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-700">Account</h2>
        <button
          onClick={handleSignOut}
          className={`mt-4 w-full rounded-md bg-purple-600 px-4 py-2 text-white hover:bg-purple-500 ${
            isSigningOut ? 'opacity-50 cursor-not-allowed' : ''
          } focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-white shadow-sm`}
          disabled={isSigningOut}
        >
          {isSigningOut ? 'Signing Out...' : 'Sign Out'}
        </button>
      </div>
    </div>
  );
};

export default Settings;
